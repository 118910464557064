import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import Emitter from 'tiny-emitter'

import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import PageContent from './customComponents/PageContent.vue'
import { VueCookieNext } from 'vue-cookie-next'
const app = createApp(App)
//app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookieNext)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('PageContent', PageContent)

app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()
app.provide('$store', store)
app.use(store).mount('#app')
