<template>
  <CRow>
    <CCol :sm="1" :md="2" :lg="3"> </CCol>
    <CCol :sm="10" :md="8" :lg="6">
      <CCard class="mb-5">
        <CCardHeader class="servicesStatusHeader">
          <CCardTitle class="mt-2 text-center text-white"
            >Create new password</CCardTitle
          >
        </CCardHeader>
        <CCardBody>
          <CForm
            v-show="!resetComplete && !resetError"
            class="g-3 m-2 mt-0"
            novalidate
            @submit.prevent="handleSubmit"
          >
            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="password">Password:</CFormLabel>
                  <CFormInput
                    v-model="model.password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    required
                    :invalid="v$.model.password.$invalid"
                    :valid="!v$.model.password.$invalid"
                    placeholder="Insert password"
                  />
                  <CFormFeedback :invalid="v$.model.password.$invalid">{{
                    v$.model.password.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="confirmPassword"
                    >Conferma Password:</CFormLabel
                  >
                  <CFormInput
                    v-model="model.confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autocomplete="new-password"
                    required
                    placeholder="Confirm password"
                    :invalid="v$.model.confirmPassword.$invalid"
                    :valid="!v$.model.confirmPassword.$invalid"
                  />
                  <CFormFeedback :invalid="v$.model.confirmPassword.$invalid">{{
                    v$.model.confirmPassword.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3 text-align-right">
                  <CButton
                    color="info"
                    class="pull-right"
                    type="submit"
                    :disabled="v$.model.$invalid"
                  >
                    <CSpinner
                      v-show="loading"
                      component="span"
                      size="sm"
                      aria-hidden="true"
                    />
                    Conferma</CButton
                  >
                </div>
              </CCol>
            </CRow>
          </CForm>
          <div v-show="resetComplete">
            <CAlert color="success">
              Your password has been changed, please wait a few seconds to be
              redirected to the login page
            </CAlert>
          </div>

          <div v-show="resetError">
            <CAlert color="danger">
              <p>An error as occured</p>
              <p>{{ errorMessage }}</p>
            </CAlert>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :sm="1" :md="2" :lg="3"> </CCol>
  </CRow>
</template>

<script>
import usersService from '../services/usersService.js'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'

export default {
  name: 'ResetPassword',
  components: {},
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data: function () {
    return {
      resetComplete: false,
      resetError: false,
      loading: false,
      model: {
        userid: '',
        token: '',
        password: '',
        confirmPassword: '',
      },
    }
  },
  validations() {
    return {
      model: {
        password: {
          required,
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsLowercase = /[a-z]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)

            return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial
            )
          },
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          confirmPassword: sameAs(this.model.password),
        },
      },
    }
  },
  computed: {},
  watch: {},

  mounted() {
    this.model.userid = this.$route.query.u
    this.model.token = this.$route.query.t
  },
  created() {},
  methods: {
    async handleSubmit() {
      this.resetError = false
      this.resetComplete = false
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        console.log(this.v$.$errors)
        return
      }
      this.loading = true

      var result = await usersService.resetPassword(this.model)
      if (result.success == 1) {
        setTimeout(() => this.$router.push({ path: '/login' }), 3000)
        this.resetComplete = true
        return
      } else {
        this.errorMessage =
          result.data.length > 0 ? result.data[0].description : ''
        this.resetError = true
      }
      this.loading = false
    },
  },
}
</script>
