<template>
  <CRow>
    <CCol :sm="1" :md="2" :lg="3"> </CCol>
    <CCol :sm="10" :md="8" :lg="6">
      <CCard class="mb-5">
        <CCardHeader class="servicesStatusHeader">
          <CCardTitle class="mt-2 text-center text-white"
            >User Activation</CCardTitle
          >
        </CCardHeader>

        <CCardBody>
          <div v-if="activationState == 2">
            <CAlert color="success">
              <p>The user has been successfully activated</p>
              <p>You will be automatically redirected to the login page</p>
            </CAlert>
            <div class="mb-3">
              <a href="#" @click="$router.push('/login')"> Return to login</a>
            </div>
          </div>
          <div v-if="activationState == 1">
            <CAlert color="danger">
              <p>Si è verificato un problema</p>
              <p>
                {{ errorMessage }}
              </p>
            </CAlert>
            <div class="mb-3">
              <a href="#" @click="$router.push('/login')"> Return to login</a>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :sm="1" :md="2" :lg="3"> </CCol>
  </CRow>
</template>

<script>
import usersService from '../services/usersService.js'
//import useVuelidate from '@vuelidate/core'
//import { required, email, minLength,sameAs } from '@vuelidate/validators'

export default {
  name: 'ActivateUser',
  components: {},
  props: {},
  setup() {
    //return { v$: useVuelidate() }
  },
  data: function () {
    return {
      activationState: 0,
      errorMessage: '',
    }
  },
  computed: {},
  watch: {},

  async mounted() {
    if (this.$route.query.t != null && this.$route.query.t != '') {
      var result = await usersService.activateUser({
        ActivationId: this.$route.query.t,
        UserId: this.$route.query.u,
      })
      if (result.success == 1) {
        this.activationState = 2
        setTimeout(() => this.$router.push({ path: '/login' }), 3000)
        return
      } else {
        this.errorMessage = result.data
      }
    }
    this.activationState = 1
  },
  created() {},
  methods: {},
}
</script>
