<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">Login in progress</h1>
            <h4 class="pt-3">Waiting please...</h4>
            <p class="text-muted"></p>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
//import usersService from '../services/usersService.js'
import { useCookie } from 'vue-cookie-next'
import router from '@/router'

export default {
  name: 'AutoSignIn',
  components: {},
  props: {},
  setup() {
    const { getCookie } = useCookie()
    const tokenValue = String(getCookie('statusPageToken'))
    if (tokenValue) {
      localStorage.setItem('usertoken', tokenValue)
      router.push('dashboard')
    } else {
      router.push('login')
    }
    //return { v$: useVuelidate() }
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},

  mounted() {},
  created() {},
  methods: {},
}
</script>
