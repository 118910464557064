<template>
  <CRow>
    <CCol :sm="1" :md="2" :lg="3"> </CCol>
    <CCol :sm="10" :md="8" :lg="6">
      <CCard class="mb-5">
        <CCardHeader class="servicesStatusHeader">
          <CCardTitle class="mt-2 text-center text-white"
            >Create new user</CCardTitle
          >
        </CCardHeader>
        <CCardBody>
          <CForm
            v-show="!registrationComplete"
            class="g-3 m-2 mt-0"
            novalidate
            @submit.prevent="handleSubmit"
          >
            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="email">Email:</CFormLabel>
                  <CFormInput
                    v-model="user.email"
                    name="email"
                    type="text"
                    required
                    placeholder="Email Address"
                    :invalid="v$.user.email.$invalid"
                    :valid="!v$.user.email.$invalid"
                  />
                  <CFormFeedback :invalid="v$.user.email.$invalid">{{
                    v$.user.email.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="firstName">First Name:</CFormLabel>
                  <CFormInput
                    v-model="user.firstName"
                    name="firstName"
                    type="text"
                    autocomplete="off"
                    required
                    placeholder="First Name"
                    :invalid="v$.user.firstName.$invalid"
                    :valid="!v$.user.firstName.$invalid"
                  />
                  <CFormFeedback :invalid="v$.user.firstName.$invalid">{{
                    v$.user.firstName.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="lastName">Last Name:</CFormLabel>
                  <CFormInput
                    v-model="user.lastName"
                    name="lastName"
                    type="text"
                    autocomplete="off"
                    required
                    placeholder="Last Name"
                    :invalid="v$.user.lastName.$invalid"
                    :valid="!v$.user.lastName.$invalid"
                  />
                  <CFormFeedback :invalid="v$.user.lastName.$invalid">{{
                    v$.user.lastName.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>

            <!--
v-model="v$.incidentModel.name.$model"
                  :invalid="v$.incidentModel.name.$invalid"
                  :valid="!v$.incidentModel.name.$invalid"

-->
            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="password">Password:</CFormLabel>
                  <CFormInput
                    v-model="user.password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    required
                    :invalid="v$.user.password.$invalid"
                    :valid="!v$.user.password.$invalid"
                    placeholder="Password"
                  />
                  <CFormFeedback :invalid="v$.user.password.$invalid">{{
                    v$.user.password.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="confirmPassword"
                    >Confirm Password:</CFormLabel
                  >
                  <CFormInput
                    v-model="user.confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autocomplete="new-password"
                    required
                    placeholder="Confirm Password"
                    :invalid="v$.user.confirmPassword.$invalid"
                    :valid="!v$.user.confirmPassword.$invalid"
                  />
                  <CFormFeedback :invalid="v$.user.confirmPassword.$invalid">{{
                    v$.user.confirmPassword.$errors.$message
                  }}</CFormFeedback>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3">
                  <Captcha
                    ref="captcha"
                    :is-valid="user.captchaValid"
                    @generateCaptcha="generateCaptcha"
                    @validChange="handleValidCaptcha"
                  ></Captcha>
                </div>
              </CCol>
            </CRow>

            <CRow v-show="errorMessage != ''">
              <div>
                <CAlert color="danger">
                  An error occurred<br />
                  {{ errorMessage }}
                </CAlert>
              </div>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3 text-align-right">
                  <CButton
                    color="info"
                    class="pull-right"
                    type="submit"
                    :disabled="v$.user.$invalid"
                  >
                    <CSpinner
                      v-show="registrationInProgress"
                      component="span"
                      size="sm"
                      aria-hidden="true"
                    />
                    Register</CButton
                  >
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="mb-3">
                  <a href="#" @click="$router.push('/login')">
                    Return to login</a
                  >
                </div>
              </CCol>
            </CRow>
          </CForm>
          <div v-show="registrationComplete">
            <CAlert color="success">
              An email has been sent to your email address, click on the link to
              activate the account
            </CAlert>
            <div class="mb-3">
              <a href="#" @click="$router.push('/login')"> Return to login</a>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :sm="1" :md="2" :lg="3"> </CCol>
  </CRow>
</template>

<script>
import usersService from '../services/usersService.js'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import Captcha from '../customComponents/Captcha.vue'

export default {
  name: 'RegistrationForm',
  components: { Captcha },
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data: function () {
    return {
      registrationComplete: false,
      validCaptcha: false,
      registrationInProgress: false,
      errorMessage: '',
      user: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        captchaValid: false,
      },
    }
  },
  validations() {
    return {
      user: {
        email: {
          required,
          email,
          valid: function (value) {
            return !(
              value.toLowerCase().includes('gmail.') |
              value.toLowerCase().includes('hotmail.') |
              value.toLowerCase().includes('yahoo.') |
              value.toLowerCase().includes('live.')
            )
          },
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },

        password: {
          required,
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsLowercase = /[a-z]/.test(value)
            const containsNumber = /[0-9]/.test(value)
            const containsSpecial = /[#?!@$%^&*-]/.test(value)

            return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial
            )
          },
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          confirmPassword: sameAs(this.user.password),
        },
        captchaValid: {
          valid: function () {
            return this.$refs.captcha.valid()
          },
        },
      },
    }
  },
  computed: {},
  watch: {},

  mounted() {},
  created() {},
  methods: {
    async handleSubmit() {
      this.errorMessage = ''
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        console.log(this.v$.$errors)
        return
      }
      this.registrationInProgress = true

      var result = await usersService.addUser(this.user)
      if (result.success == 1) {
        this.registrationComplete = true
      } else {
        this.errorMessage = result.data

        this.registrationComplete = false
      }
      this.registrationInProgress = false
    },
    handleValidCaptcha(newState) {
      this.user.captchaValid = newState
    },
  },
}
</script>
