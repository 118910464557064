<template>
  <div v-show="visible" class="h-100 content-center pb-3">
    <CCard class="h-100 border-0">
      <CCardBody>
        <div class="">
          <div class="scrollingcontent">
            <slot> </slot>
          </div>
        </div>
      </CCardBody>
      <CCardFooter v-if="showFooter">
        <slot name="footer"></slot>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'PageContent',
  components: {},
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {}
  },
  mounted() {},
}
</script>
