import axios from 'axios'
import router from '@/router'

axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
const API_URL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (error == 'Error: Network Error') {
      //store.commit('cancelPendingRequests');
      //router.push('/pages/maintenance');
    } else if (error.response.status === 401) {
      if (
        router.currentRoute.value.meta.requireBackofficeAuth != undefined &&
        router.currentRoute.value.meta.requireBackofficeAuth == true
      ) {
        localStorage.setItem('backofficetoken', '')
        localStorage.setItem('azureAccount', '')
        localStorage.setItem('azureAccountId', '')
        router.push('/admin_backoffice')
      } else if (
        router.currentRoute.value.meta.requiresAuth != undefined &&
        router.currentRoute.value.meta.requiresAuth == true
      ) {
        localStorage.setItem('usertoken', '')
        router.push('/login')
      }
    } else if (error.response.status === 403) {
      //router.push('/pages/404');
    } else if (error.response.status === 404) {
      //router.push('/pages/404');
    } else if (error.response.status === 500) {
      //console.log(error.response)
      //             //router.push('/pages/500');
    } else {
      //console.log(error.response)
    }
    return Promise.reject(error)
  },
)

export { API_URL, axios, router }
