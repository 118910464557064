import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    azureAccountId: '',
    msalConfig: {
      auth: {
        clientId: '31152d52-c80b-43e6-93c6-654a77c52f41',
        authority:
          'https://login.microsoftonline.com/c122579f-b475-4362-a2d9-e77a3db7cb23',
      },
      scopes: ['api://31152d52-c80b-43e6-93c6-654a77c52f41/access_as_user'],
      cache: {
        cacheLocation: 'localStorage',
      },
    },
    accessToken: '',
    account: undefined,
  },
  mutations: {
    setAzureAccountId(state, accountId) {
      state.azureAccountId = accountId
    },

    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    account(state, account) {
      state.account = account
    },
  },
  actions: {},
  getters: {
    getAzureAccountId(state) {
      return state.accessToken
    },
    getAccessToken(state) {
      return state.accessToken
    },
  },
  modules: {},
})
