import { createRouter, createWebHistory } from 'vue-router'
import RegistrationForm from '@/views/RegistrationForm.vue'
import ActivateUser from '@/views/ActivateUser.vue'
import AutoSignIn from '@/views/AutoSignIn.vue'

import LoginForm from '@/views/LoginForm.vue'
import ResetPassword from '@/views/ResetPassword.vue'

const routes = [
  {
    path: '/',
    name: 'Status Page',
    redirect: 'dashboard',
    component: () => import('@/layouts/DefaultLayout.vue'),

    children: [
      {
        path: '/registration',
        name: 'registration',
        component: RegistrationForm,
      },
      {
        path: '/activateuser',
        name: 'ActivateUser',
        component: ActivateUser,
      },
      {
        path: '/ResetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
      },

      {
        path: '/login',
        name: 'login',
        component: LoginForm,
      },
      {
        path: '/signIn',
        name: 'signin',
        component: AutoSignIn,
      },

      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
        },

        component: () => import('@/views/StatusPage.vue'),
      },
      {
        path: 'incidentsHistory',
        name: 'Incidents History',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/IncidentsHistory.vue'),
      },
    ],
  },

  {
    path: '/admin_backoffice',
    name: 'Status Page Backoffice',
    component: () => import('@/layouts/BackofficeLayout.vue'),
    //DefaultLayout,
    redirect: '/admin_backoffice',
    children: [
      {
        path: '/Admin_Backoffice',
        name: 'Backoffice',
        meta: {
          requireBackofficeAuth: true,
        },
        component: () => import('@/views/Backoffice.vue'),
      },
      {
        path: '/Admin_AuditLog',
        name: 'AuditLog',

        meta: {
          requireBackofficeAuth: true,
        },

        component: () => import('@/views/AuditLog.vue'),
      },

      {
        path: '/Admin_Users',
        name: 'Users',

        meta: {
          requireBackofficeAuth: true,
        },

        component: () => import('@/views/Users.vue'),
      },

      {
        path: '/test',
        name: 'test',

        meta: {
          requireBackofficeAuth: true,
        },

        component: () => import('@/views/BackofficeMaster.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem('usertoken')
  const isAuthenticated = token != null && token != ''
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next('/login')
  } else {
    next()
  }
})

export default router
