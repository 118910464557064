<template>
  <div>
    <CFormLabel for="captcha">Insert the image test:</CFormLabel>
    <div class="captcha-container">
      <div class="button-container">
        <CButton
          id="newCaptch"
          type="button"
          color="info"
          class="norRound"
          @click="generateCaptcha"
        >
          <CIcon icon="cilSync" />
        </CButton>
      </div>
      <div class="canvas-container">
        <canvas
          ref="captchaCanvas"
          width="180"
          height="38"
          class="captcha-canvas"
        ></canvas>
      </div>
      <div class="text-container">
        <CFormInput
          id="captcha"
          v-model="captchaInput"
          name="captcha"
          type="text"
          autocomplete="off"
          class="form-control"
          placeholder=""
          :invalid="captchaInput != captchaText"
          :valid="captchaInput == captchaText && captchaInput != null"
        />
      </div>
    </div>

    <!-- <CFormInput placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1"/> -->
  </div>
</template>

<script>
export default {
  name: 'CaptchaCheck',
  props: {
    isValid: {
      type: Boolean,
      required: true, // Rende la prop obbligatoria
    },
  },
  data() {
    return {
      captchaInput: '',
      captchaText: '',
    }
  },

  watch: {
    captchaInput(newVal) {
      this.$emit('validChange', newVal == this.captchaText)
    },
  },
  mounted() {
    this.generateCaptcha()
  },

  methods: {
    generateRandomCaptchaText() {
      // Genera un testo CAPTCHA casuale
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789?!%$@#'
      let captchaText1 = ''

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        captchaText1 += characters.charAt(randomIndex)
      }

      return captchaText1
    },

    valid() {
      return this.captchaInput != '' && this.captchaInput == this.captchaText
    },

    generateCaptcha() {
      const canvas = this.$refs.captchaCanvas
      const context = canvas.getContext('2d')
      this.captchaText = this.generateRandomCaptchaText()
      context.clearRect(0, 0, canvas.width, canvas.height)
      // Calcola la larghezza totale del testo CAPTCHA
      const totalWidth = this.captchaText.length * 30 // 30 è la dimensione predefinita del carattere

      // Calcola la posizione x iniziale in modo che il testo sia centrato
      const startX = (canvas.width - totalWidth) / 2 + 30

      let currentX = startX

      // Imposta l'allineamento del testo al centro
      context.textAlign = 'center'

      // Disegna ciascuna lettera del testo CAPTCHA con font e allineamento verticale casuale
      for (let i = 0; i < this.captchaText.length; i++) {
        const letter = this.captchaText[i]

        // Genera una dimensione del carattere casuale tra 15 e 30
        const fontSize = Math.floor(Math.random() * 15) + 17
        context.font = `bold ${fontSize}px Arial`

        // Genera un allineamento verticale casuale tra 15 e 30
        const verticalAlignment = Math.floor(Math.random() * 10) + 22

        // Disegna la lettera
        context.fillStyle = '#333'
        context.fillText(letter, currentX, verticalAlignment)

        // Aggiorna la posizione x per la prossima lettera
        currentX += context.measureText(letter).width + 10 // Calcola la larghezza effettiva della lettera
      }
    },
  },
}
</script>

<style scoped>
.captcha-canvas {
  padding: 0px;
  margin: 0px;
}

.captcha-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.canvas-container,
.text-container {
  flex: 1;
  text-align: center;
  padding: 0px;
  margin: 0px;
  height: 38px;
}

.button-container {
  padding: 0px;
  margin: 0px;
}

.canvas-container {
  border: 1px solid #ccc; /* Aggiungi un bordo alla canvas */
  padding: 0px;
  margin: 0px;
}

.text-container {
  flex: 2; /* Fai in modo che la casella di testo occupi più spazio */
}

.norRound {
  border-radius: 0px !important;
}
</style>
