<template>
  <CRow>
    <CCol :sm="1" :md="3" :lg="4"> </CCol>
    <CCol :sm="10" :md="6" :lg="4">
      <CCard v-if="mode == 0" class="mb-5">
        <CCardHeader class="servicesStatusHeader">
          <CCardTitle class="mt-2 text-center text-white"
            >ReeVo Status Page</CCardTitle
          >
        </CCardHeader>
        <CCardBody>
          <CForm class="g-3 m-2 mt-0" novalidate @submit.prevent="handleSubmit">
            <CRow class="mt-3">
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="username">User Name:</CFormLabel>
                  <CFormInput
                    id="username"
                    v-model="loginModel.userName"
                    name="username"
                    autocomplete="username"
                    type="email"
                    required
                    placeholder="Username / Email address"
                    @update:modelValue="loginErrorMessage = ''"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="password">Password:</CFormLabel>
                  <CFormInput
                    id="password"
                    v-model="loginModel.password"
                    name="password"
                    type="password"
                    required
                    placeholder="Password"
                    autocomplete="current-password"
                    @update:modelValue="loginErrorMessage = ''"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div class="mb-3 text-align-right">
                  <CButton
                    color="info"
                    type="submit"
                    class="pull-right"
                    :disabled="v$.loginModel.$invalid || loading"
                  >
                    <CSpinner
                      v-show="loading"
                      component="span"
                      size="sm"
                      aria-hidden="true"
                    />
                    Login</CButton
                  >
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <CAlert
                  v-if="loginErrorMessage != ''"
                  color="danger"
                  class="mb-3 text-align-center"
                >
                  {{ loginErrorMessage }}
                </CAlert>
              </CCol>
            </CRow>
          </CForm>

          <CRow>
            <CCol>
              <div class="mb-3">
                <a href="#" @click="mode = 1"> Recover password</a>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div class="mb-3">
                <a href="/Registration"> Sign In</a>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <CCard v-if="mode == 1" class="mb-5">
        <CCardHeader class="servicesStatusHeader">
          <CCardTitle class="mt-2 text-center text-white"
            >Recover password</CCardTitle
          >
        </CCardHeader>
        <CCardBody>
          <CForm
            class="g-3 m-2 mt-0"
            novalidate
            @submit.prevent="handleRecoverSubmit"
          >
            <CRow class="mt-3">
              <CCol>
                <div class="mb-3">
                  <CFormLabel for="recoverusername">Email Address:</CFormLabel>
                  <CFormInput
                    id="recoverusername"
                    v-model="recoverModel.email"
                    name="recoverusername"
                    autocomplete="off"
                    type="email"
                    :invalid="v$.recoverModel.email.$invalid"
                    :valid="!v$.recoverModel.email.$invalid"
                    placeholder="User Name / Email Address"
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="mb-3 text-align-right">
                  <CButton
                    color="info"
                    type="submit"
                    class="pull-right"
                    :disabled="v$.recoverModel.$invalid || loading"
                    >Recover Password</CButton
                  >
                </div>
              </CCol>
            </CRow>
            <CRow v-if="recoverErrorMessage != ''">
              <CCol>
                <CAlert color="danger">
                  {{ recoverErrorMessage }}
                </CAlert>
              </CCol>
            </CRow>
            <CRow v-if="complete == true">
              <CCol>
                <CAlert color="success">
                  An email has been sent to your email address, follow the
                  instructions to reset password
                </CAlert>
              </CCol>
            </CRow>
          </CForm>
          <CRow>
            <CCol>
              <div class="mb-3">
                <a href="#" @click="mode = 0"> Back</a>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :sm="1" :md="3" :lg="4"> </CCol>
  </CRow>
</template>
<script>
import UsersService from '../services/usersService.js'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'AuditLog',
  components: {},
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data: function () {
    return {
      loading: false,
      complete: false,
      recoverErrorMessage: '',
      loginErrorMessage: '',
      mode: 0,
      loginModel: {
        userName: '',
        password: '',
      },
      recoverModel: {
        email: '',
      },
    }
  },
  validations() {
    return {
      loginModel: {
        userName: {
          required,
          email,
        },
        password: {
          required,
        },
      },
      recoverModel: {
        email: {
          required,
          email,
        },
      },
    }
  },
  mounted() {},
  created() {},
  methods: {
    async handleSubmit() {
      this.loginErrorMessage = ''
      this.loading = true
      await UsersService.authenticateUser(this.loginModel)
        .then((response) => {
          if (response.success == 1) {
            localStorage.setItem('usertoken', response.data.token)
            this.$router.push('dashboard')
            this.loading = false

            this.$emitter.emit('userloggedin')
          } else {
            this.$emitter.emit('modalinfo', {
              title: 'titolo',
              content: response.data,
            })
            this.loginErrorMessage = response.data
          }
        })
        .catch((error) => {
          alert(error)
        })
      this.loading = false
    },

    async handleRecoverSubmit() {
      this.recoverErrorMessage = ''
      this.loading = true
      this.complete = false
      await UsersService.recoverPassword(this.recoverModel)
        .then((response) => {
          if (response.success == 1) {
            this.complete = true
            setTimeout(() => (this.mode = 0), 2000)
          } else {
            this.recoverErrorMessage = response.data
          }
        })
        .catch((error) => {
          alert(error)
        })
      this.loading = false
    },
  },
}
</script>
