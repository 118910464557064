import authHeader from './auth-headerbackoffice'
import { API_URL, axios } from '../services/baseService.js'

class UsersService {
  getUsers() {
    return axios.get(API_URL + 'Users', { headers: authHeader() })
  }

  async addUser(user) {
    return await axios
      .post(API_URL + 'Account/register', user, { headers: authHeader() })
      .then(
        (response) => {
          if (response.status == 200) {
            return { data: null, success: 1 }
          }
          return { data: response.data, success: 0 }
        },
        (error) => {
          return { data: error.response.data, success: 0 }
        },
      )
  }

  async removeUser(userId) {
    return await axios
      .delete(API_URL + 'Users/' + userId, { headers: authHeader() })
      .then(
        (response) => {
          if (response.data) {
            return { data: response.data, success: 1 }
          }
          return { data: response.data, success: 0 }
        },
        (error) => {
          return { data: error.response.data, success: 0 }
        },
      )
  }

  authenticateUser(loginModel) {
    return axios.post(API_URL + 'auth/login', loginModel).then(
      (response) => {
        if (response.data) {
          return { data: response.data, success: 1 }
        }
        return { data: response.data, success: 0 }
      },
      (error) => {
        return { data: error.response.data, success: 0 }
      },
    )
  }

  recoverPassword(recoverModel) {
    return axios.post(API_URL + 'account/forgotpassword', recoverModel).then(
      (response) => {
        if (response.data) {
          return { data: response.data, success: 1 }
        }
        return { data: response.data, success: 0 }
      },
      (error) => {
        return { data: error.response.data, success: 0 }
      },
    )
  }

  resetPassword(resetModel) {
    return axios.post(API_URL + 'account/resetpassword', resetModel).then(
      (response) => {
        if (response.data) {
          return { data: response.data, success: 1 }
        }
        return { data: response.data, success: 0 }
      },
      (error) => {
        return { data: error.response.data, success: 0 }
      },
    )
  }

  activateUser(activateModel) {
    return axios.post(API_URL + 'account/Activate', activateModel).then(
      (response) => {
        if (response.data) {
          return { data: response.data, success: 1 }
        }
        return { data: response.data, success: 0 }
      },
      (error) => {
        return { data: error.response.data, success: 0 }
      },
    )
  }
}

export default new UsersService()
